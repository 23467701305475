<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title mt-4">
              <i class="fa-solid fa-table-list" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="gpath_career_univAdd()">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col sm="6" md="3" lg="3" xl="3" class="mb-2">
                <select v-model="whereFilter.country" class="form-control" @change="setCountry">
                  <option v-for="(country, index) of country" :key="(index+1)" :value="country.country">
                    {{country.country}}
                  </option>
                </select>
              </b-col>
              <b-col class="col-7 col-sm-4 col-md-3 col-lg-3 mb-3">
                <div v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'">
                  <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button" />
                  <CsvUpload :propModulePrefix="cvModulePrefix" class="btn Download_button"  />
                </div>
                <VueJsonToCsv :json-data="gpath_career_univObjList" csv-title="gpath_career_univ_list" v-if="gpath_career_univObjList && gpath_career_univObjList.length">
                  <b-btn variant="primary" class="Download_button">CSV</b-btn>
                </VueJsonToCsv>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="gpath_career_univObjList && gpath_career_univObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="gpath_career_univObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(name)="data">
                    {{ data.item.name }}
                    <br>
                    <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="gpath_career_univEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="showGPathCareerUnivDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelGPathCareerUnivAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <GPathCareerUnivAdd :propOpenedInModal="true" @emitCloseGPathCareerUnivAddModal="closeGPathCareerUnivAddModal()" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpath_career_univAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathCareerUnivAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelGPathCareerUnivEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl"
    >
      <GPathCareerUnivEdit :propOpenedInModal="true" :propGPathCareerUnivObj="gpath_career_univEditObj" @emitCloseGPathCareerUnivEditModal="closeGPathCareerUnivEditModal()" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpath_career_univEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathCareerUnivEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelGPathCareerUnivDelete" title="Delete" ok-title="Delete" cancel-title="Cancel" size="xl">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelGPathCareerUnivDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="gpath_career_univDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { GPathCareerUnivs } from "../../../FackApi/api/GPathCareerUniv.js"
import GPathCareerUnivAdd from "./GPathCareerUnivAdd"
import GPathCareerUnivEdit from "./GPathCareerUnivEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"

export default {
  name: "GPathCareerUnivList",
  components: {
    GPathCareerUnivAdd,
    GPathCareerUnivEdit,
    CsvDownload,
    CsvUpload
  },
  data () {
    return {
      apiName: "gpath_career_univ_list",
      cvModuleFile: "gpath_career_univ",
      cvModulePrefix: "GCU",
      cvCardTitle: "GPath Career University",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit GPathCareerUniv",
      cvAddModalHeader: "Add GPathCareerUniv",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPathCareerUniv List Response",
      showModelGPathCareerUnivAdd: false,
      showModelGPathCareerUnivEdit: false,
      showModelGPathCareerUnivDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "name", key: "name", class: "text-left align-text-top", sortable: true },
        { label: "career univ key", key: "career_univ_key", class: "text-left align-text-top", sortable: true },
        { label: "country", key: "country", class: "text-left align-text-top", sortable: true },
        { label: "univ name", key: "univ_name", class: "text-left align-text-top", sortable: true },
        { label: "univ url", key: "univ_url", class: "text-left align-text-top", sortable: true },
        { label: "detail", key: "detail", class: "text-left align-text-top", sortable: true },
        { label: "univ qr image", key: "univ_qr_image", class: "text-left align-text-top", sortable: true }
      ],
      gpath_career_univObjList: null,
      gpath_career_univEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {
        country: ""
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    this.gpath_career_univList()
    this.gpath_career_univ_countryList()
  },
  methods: {
    /**
     * set setCountry
     */
    async setCountry () {
      this.gpath_career_univList()
    },
    /**
     * gpath_career_univList
     */
    async gpath_career_univList () {
      try {
        let gpathCareerUnivListResp = await GPathCareerUnivs.gpath_career_univList(this, this.whereFilter)
        if (gpathCareerUnivListResp.resp_status) {
          this.gpath_career_univObjList = gpathCareerUnivListResp.resp_data.data
          this.totalRows = gpathCareerUnivListResp.resp_data.count
        }
        else {
          this.toastMsg = gpathCareerUnivListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at gpath_career_univList() and Exception:", err.message)
      }
    },
    /**
     * gpath_career_univ_countryList
     */
    async gpath_career_univ_countryList () {
      try {
        let gpathCareerCountryListResp = await GPathCareerUnivs.gpath_career_univ_countryList(this)
        if (gpathCareerCountryListResp.resp_status) {
          this.gpath_career_countryObjList = gpathCareerCountryListResp.resp_data.data
          this.country = this.gpath_career_countryObjList
        }
        else {
          this.toastMsg = gpathCareerCountryListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at gpath_career_univ_countryList() and Exception:", err.message)
      }
    },
    /**
     * gpath_career_univAdd
     */
    gpath_career_univAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpath_career_univ_add")
        }
        else {
          this.showModelGPathCareerUnivAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_career_univAdd() and Exception:", err.message)
      }
    },
    /**
     * gpath_career_univEdit
     */
    gpath_career_univEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpath_career_univ_edit/" + this.gpath_career_univEditObj.gcu_id)
        }
        else {
          this.gpath_career_univEditObj = item
          this.showModelGPathCareerUnivEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_career_univEdit() and Exception:", err.message)
      }
    },
    /**
     * showGPathCareerUnivDeleteDialog
     */
    showGPathCareerUnivDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelGPathCareerUnivDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showGPathCareerUnivDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * gpath_career_univDelete
     */
    async gpath_career_univDelete () {
      try {
        let gpathCareerUnivDelResp = await GPathCareerUnivs.gpath_career_univDelete(this, this.delObj.gcu_id)
        await ApiResponse.responseMessageDisplay(this, gpathCareerUnivDelResp)

        if (gpathCareerUnivDelResp && !gpathCareerUnivDelResp) {
          this.showModelGPathCareerUnivDelete = false
          return false
        }

        let index = this.gpath_career_univObjList.indexOf(this.delObj)
        this.gpath_career_univObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelGPathCareerUnivDelete = false
      }
      catch (err) {
        console.error("Exception occurred at gpath_career_univDelete() and Exception:", err.message)
      }
    },
    /**
     * closeGPathCareerUnivAddModal
     */
    closeGPathCareerUnivAddModal (gpathCareerUnivAddData) {
      try {
        if (gpathCareerUnivAddData) {
          if (this.gpath_career_univObjList && this.gpath_career_univObjList.length >= 1) {
            let gpathCareerUnivObjLength = this.gpath_career_univObjList.length
            let lastId = this.gpath_career_univObjList[gpathCareerUnivObjLength - 1]["id"]
            gpathCareerUnivAddData.id = lastId + 1
          }
          else {
            this.gpath_career_univObjList = []
            gpathCareerUnivAddData.id = 11111
          }

          gpathCareerUnivAddData.created_on = moment()
          this.gpath_career_univObjList.unshift(gpathCareerUnivAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelGPathCareerUnivAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closegpath_career_univAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeGPathCareerUnivEditModal
     */
    closeGPathCareerUnivEditModal () {
      try {
        this.showModelGPathCareerUnivEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeGPathCareerUnivEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
